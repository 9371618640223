import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import { SCHEMA_MAP } from 'schemas';

const SEO = ({
  title,
  description,
  lang,
  noIndex,
  canonical,
  metaImage,
  schemaType,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            siteName
            title
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const { siteName, siteUrl } = site.siteMetadata;

  const metaImageURL =
    metaImage?.gatsbyImageData?.images?.fallback?.src?.startsWith('//')
      ? `https:${metaImage}`
      : `${siteUrl}${metaImage}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={metaTitle}
      titleTemplate={`%s | ${metaTitle}`}
    >
      <script type="application/ld+json">
        {JSON.stringify(SCHEMA_MAP[schemaType])}
      </script>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content="Strike, online, estate, agents" />
      <link rel="canonical" href={`${siteUrl}${canonical}`} />
      {metaImage && <meta name="image" content={metaImageURL} />}
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="og:site_name" content={siteName} />
      <meta name="og:title" content={metaTitle} />
      <meta name="og:url" content={`${siteUrl}${canonical}`} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:image" content={metaImageURL} />
      <meta name="og:type" content="website" />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  noIndex: PropTypes.bool,
  canonical: PropTypes.string,
  metaImage: PropTypes.string,
  schemaType: PropTypes.string,
};

export default SEO;
