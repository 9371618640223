import Home from './Homepage';
import Reviews from './Reviews';

export const TYPES = {
  HOME: 'home',
  REVIEWS: 'reviews',
};

export const SCHEMA_MAP = {
  [TYPES.HOME]: Home,
  [TYPES.REVIEWS]: Reviews,
};
