export default {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Strike',
  url: 'https://strike.co.uk',
  logo: `${process.env.GATSBY_STATIC_ASSETS}/images/strike.png`,
  address: {
    '@type': 'PostalAddress',
    streetAddress: '650 The Crescent, Colchester Business Park',
    addressLocality: 'Colchester',
    addressRegion: 'Essex',
    postalCode: 'CO4 9YQ',
    addressCountry: 'England',
  },
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+44 808 109 9607',
    contactType: 'Customer Service',
    areaServed: 'UK',
  },
};
