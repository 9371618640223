export default {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  image: `${process.env.GATSBY_STATIC_ASSETS}/images/strike.png`,
  legalName: 'Strike',
  telephone: '+44 808 109 9607',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '650 The Crescent, Colchester Business Park',
    addressLocality: 'Colchester',
    addressRegion: 'Essex',
    postalCode: 'CO4 9YQ',
    addressCountry: 'England',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '5',
    reviewCount: '9',
  },
  review: [
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'Thomas',
        familyName: 'Curran',
      },
      headline: 'The future in selling houses',
      reviewBody: `The future in selling houses...best experience of an estate agent I've ever had.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      headline: 'Please use with absolute confidence',
      author: {
        '@type': 'Person',
        givenName: 'Andrew',
        familyName: 'Peers',
      },
      reviewBody: `Can’t praise this company enough. On the ball all the time. Please use with absolute confidence.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'Nicola',
      },
      headline: 'Best of all it was FREE!',
      reviewBody: `Excellent and efficient service...friendly and professional staff, but best of all it was FREE!`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'Lynn',
        familyName: 'Chan',
      },
      headline: 'Fautless, would highly recommend them',
      reviewBody: `Faultless service & great customer experience. I'm recommending them to everyone I know.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      headline: `We couldn't believe it was free.`,
      author: {
        '@type': 'Person',
        givenName: 'Tom',
        familyName: 'Allen',
      },
      reviewBody: `We couldn't believe it was free. Strike were really easy to deal with, from the moment we first made contact it was just a pleasure. The savings are huge, and throughout the whole process you’re in control. I'd recommend using Strike to anyone.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      aauthor: {
        '@type': 'Person',
        givenName: 'Lillian',
        familyName: 'Hunt',
      },
      headline: 'Fantastic, patient and always available',
      reviewBody:
        'Excellent company, who guide you through every step of the process. The staff are fantastic, patient and always available.',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'Emma',
        familyName: 'Howard',
      },
      headline: 'It was quick and easy',
      reviewBody:
        'Selling a house can often be frustrating and stressful but with Strike it was really quick and easy.',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'Carl',
      },
      headline: 'I sold within 2 weeks',
      reviewBody:
        'They sold my house within 2 weeks completely free of charge! Thank you.',
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'David',
        familyName: 'Steward',
      },
      headline: `I'd give them 10 stars`,
      reviewBody: `This is the best estate agent in the UK — no question. I'd give them 10 stars.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
    {
      '@type': 'Review',
      author: {
        '@type': 'Person',
        givenName: 'David',
        familyName: 'Steward',
      },
      headline: `I'd give them 10 stars`,
      reviewBody: `This is the best estate agent in the UK — no question. I'd give them 10 stars.`,
      reviewRating: {
        '@type': 'Rating',
        bestRating: '5',
        ratingValue: '5',
        worstRating: '1',
      },
    },
  ],
};
